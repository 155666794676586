@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'pretendard/dist/web/static/pretendard.css';

@layer base {
  html {
    font-family: Pretendard, system-ui, sans-serif;
  }
}

*::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none;  /* IE and 엣지 */
  scrollbar-width: none;  /* 파이어폭스 */
}

button:active svg *{
  fill:black;
}
a{
  text-decoration: underline;
}
a:hover,a:active{
  background:#e6ff00;
  color:black;
}
.info-content p{
  margin-top:1em;
}
.info-content p:first-child{
  margin-top:0;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
